const getDefaultState = () => {
  return {
    // global
    activeTab: 0,

    // tab detail stk
    tabDetailStk: {},

    // tab detail spp
    tabDetailSpp: {},

    // tab pekerjaan
    tabPekerjaan: {}
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {},

  mutations: {
    SET_ACTIVE_TAB (state, tabIndex) {
      document.body.scrollTop = 0 // safari
      document.documentElement.scrollTop = 0 // chrome, firefox, ie and opera
      state.activeTab = tabIndex
    },
    SET_TAB_DETAIL_STK (state, payload) {
      state.tabDetailStk = payload
    },
    SET_TAB_DETAIL_SPP (state, payload) {
      state.tabDetailSpp = payload
    },
    SET_TAB_PEKERJAAN (state, payload) {
      state.tabPekerjaan = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
